import axios from "./axios";

export default {
    createAccountingEntryHeader(data){
        const options = {
            method: 'POST',
            url: '/accountingentryheader',
            headers: {'content-type': 'application/json'},
            data
        }
        return new Promise( ( resolve, reject ) => {
            axios(options)
                .then( response => {

                    if( response === undefined ){
                        reject( 'No se obtuvo respuesta del servidor' );
                    } else if( response.data.success ){
                        resolve( response.data );
                    } else {
                        reject( response.data );
                    }
                } )
                .catch( error => {
                    reject( error );
                } );
        } )
    },
    getAccountingItemsDetails( data ) {
        const options = {
            method: 'POST',
            url: '/accountingentryheader/getdetails',
            headers: { 'content-type': 'application/json' },
            data
        };
        return new Promise( ( resolve, reject ) => {
            axios( options )
                .then( response => {
                    if( response === undefined ){
                        reject( 'No se obtuvo respuesta del servidor' );
                    } else if( response.data.success ){
                        resolve( response.data );
                    } else {
                        reject( response.data );
                    }
                } )
                .catch( error => {
                    reject( error );
                } );
        } );
    },
    getOneAcountingHeader( id ){
        const options = {
            method: 'GET',
            url: '/accountingentryheader/getOne/',
            headers: {'content-type': 'application/json'},
            params: {
                id
            }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    else if(response.data.success){
                        resolve(response.data);
                    }else{
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateAccountingEntryHeader(data){
        const options = {
            method: 'PUT',
            url: '/accountingentryheader',
            headers: {'content-type': 'application/json'},
            data
        }
        return new Promise( ( resolve, reject ) => {
            axios(options)
                .then( response => {

                    if( response === undefined ){
                        reject( 'No se obtuvo respuesta del servidor' );
                    } else if( response.data.success ){
                        resolve( response.data );
                    } else {
                        reject( response.data );
                    }
                } )
                .catch( error => {
                    reject( error );
                } );
        } )
    },
    updateAccountingCorrelatives(data){
        const options = {
            method: 'PUT',
            url: '/accountingentryheader/fixCorrelatives',
            headers: {'content-type': 'application/json'},
            data
        }
        return new Promise( ( resolve, reject ) => {
            axios(options)
                .then( response => {
                    if( response === undefined ){
                        reject( 'No se obtuvo respuesta del servidor' );
                    } else if( response.data.success ){
                        resolve( response.data );
                    } else {
                        reject( response.data );
                    }
                } )
                .catch( error => {
                    reject( error );
                } );
        } )
    },
    deleteAccountingEntryHeader(data){
        const options = {
            method: 'DELETE',
            url: '/accountingentryheader',
            headers: {'content-type': 'application/json'},
            data
        }
        return new Promise( ( resolve, reject ) => {
            axios(options)
                .then( response => {

                    if( response === undefined ){
                        reject( 'No se obtuvo respuesta del servidor' );
                    } else if( response.data.success ){
                        resolve( response.data );
                    } else {
                        reject( response.data );
                    }
                } )
                .catch( error => {
                    reject( error );
                } );
        } )
    },
    async bulkCreateAccountingEntryHeader( data ) {
        try {
            const options = {
                method: 'POST',
                url: '/accountingentryheader/bulk',
                headers: {
                    'content-type': 'application/json'
                },
                ...data
            };

            const { data: responseData } = await axios( options );
            const { success } = responseData;

            if ( !success ) {
                throw new Error( responseData.message );
            }

            return responseData;
        } catch (error) {
            throw new Error(`${error.message}`);
        }
    }
}